import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';



const DownloadCsvErrorModal = ({ modalOpen }) => {
  const navigate = useNavigate();
  const { setSpareAuth, logout } = useContext(AuthContext);

  return (
    <Dialog open={modalOpen}>
      <DialogTitle textAlign="center"></DialogTitle>
      <DialogContent style={{ padding: '20px 30px 20px 30px' }}>
        <DialogContentText>
          There was an issue retrieving data from SpareFoot. This could be due to high traffic on the SpareFoot platform. Please try again later.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <MDButton variant="outlined" color="error" onClick={() => {
           navigate('/sign-in');
           setSpareAuth(false);
           logout();
        }}>OK</MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadCsvErrorModal;
